import { Component, ErrorInfo, ReactNode } from 'react';

import { ErrorModal } from './ErrorModal';

type ErrorBoundaryProps = {
  error?: string | ReactNode;
  children: ReactNode;
};

type ErrorBoundaryState = {
  hasError: boolean;
};

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static defaultProps = {
    error: (
      <>
        <p>
        We're sorry, but an unexpected error occurred. Please try again.
        </p>
        <p>Try going Back, or back to Home</p>
      </>
    ),
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <ErrorModal message={this.props.error} />
        </div>
      );
    }

    return this.props.children;
  }
}
