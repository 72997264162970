import { ComponentType, lazy, ReactElement } from "react";
import { ErrorBoundary } from "./ErrorBoundary";

function retryImport(
  importFn: () => Promise<{ default: ComponentType }>,
  retriesLeft: number = 3,
  interval: number = 1000
): Promise<{ default: ComponentType }> {
  return new Promise((resolve, reject) => {
    importFn()
      .then(resolve)
      .catch((error) => {
        if (retriesLeft <= 1) {
          reject(error);
        } else {
          setTimeout(() => {
            retryImport(importFn, retriesLeft - 1, interval).then(resolve).catch(reject);
          }, interval);
        }
      });
  });
}

export function loadWithRetry(
  importFn: () => Promise<{ default: ComponentType}>,
  retriesLeft: number = 3,
  interval: number = 1000
): (props) => ReactElement {

  const LazyComponent = lazy(() => retryImport(importFn, retriesLeft, interval));

  return (props) => (
    <ErrorBoundary error="An error has occurred while loading the module.">
        <LazyComponent {...props} />
    </ErrorBoundary>
  );
}