import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

type ErrorModalProps = {
    message?: string | ReactNode;
  };

export const ErrorModal = (props: ErrorModalProps) => {

    const {message = "We're sorry, but an unexpected error occurred. Please try again."} = props;

    const navigate = useNavigate();

    return (
        <Modal isOpen={true} >
        <ModalHeader>Oops! Something went wrong</ModalHeader>
        <ModalBody>
            <div>
                {typeof message === "string" ? <p>{message}</p> : message}
            </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => window.location.replace("/")}>
            Home
          </Button>{' '}
          <Button color="secondary" onClick={() => navigate(-1)}>
            Back
          </Button>
        </ModalFooter>
      </Modal>
    )
}